<template>
  <div class="recommend-list-content">
    <p class="article-main-title">推荐文章</p>
    <ul>
        <li>
            <img class="recommend-article-pic" src="https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/3196bb3d1f5842e7b530d8ac1b1adb60_mergeImage.png" alt="文章图片" >
            <div class="recommend-article-info">
                <p class="recommend-article-title">绝区零哪个角色更强 绝区零强度排行榜单揭秘</p>
                <p class="recommend-article-time">2024-07-18 08:30:00</p>
            </div>
        </li>
        <li>
            <img class="recommend-article-pic" src="https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/073caffe39e7458e83322efbe2c6e9d7_mergeImage.png" alt="文章图片" >
            <div class="recommend-article-info">
                <p class="recommend-article-title">英雄联盟买号如何确保安全 LOL账号交易平台推荐</p>
                <p class="recommend-article-time">2024-07-18 08:30:00</p>
            </div>
        </li>
        <li>
            <img class="recommend-article-pic" src="https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/a8ec6a42876d4bf680f7d0ef5d2522f2_mergeImage.png" alt="文章图片" >
            <div class="recommend-article-info">
                <p class="recommend-article-title">逆水寒手游自选金打造使用推荐 全新职业PVE一图流</p>
                <p class="recommend-article-time">2024-07-18 08:30:00</p>
            </div>
        </li>
        <li>
            <img class="recommend-article-pic" src="https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/ef901fca803e40bbb96399c2969a30e0_mergeImage.png" alt="文章图片" >
            <div class="recommend-article-info">
                <p class="recommend-article-title">星际战甲月球密室卡一览表 什么是星际战甲月球密室卡</p>
                <p class="recommend-article-time">2024-07-18 08:30:00</p>
            </div>
        </li>
        <li>
            <img class="recommend-article-pic" src="https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/c01d2f75ea1843ef83dd1d04b6519a5d_mergeImage.png" alt="文章图片" >
            <div class="recommend-article-info">
                <p class="recommend-article-title">英雄联盟电信区有哪些 英雄联盟电信区有哪些适合新手</p>
                <p class="recommend-article-time">2024-07-18 08:30:00</p>
            </div>
        </li>
        <li>
            <img class="recommend-article-pic" src="https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/470333d24b984d47b7da5184a2072894_mergeImage.png" alt="文章图片" >
            <div class="recommend-article-info">
                <p class="recommend-article-title">英雄联盟账号交易哪个平台好 靠谱的账号交易app分享</p>
                <p class="recommend-article-time">2024-07-18 08:30:00</p>
            </div>
        </li>
        <li>
            <img class="recommend-article-pic" src="https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/14ed11e47d814a279cd2e2d6f4075c01_mergeImage.png" alt="文章图片" >
            <div class="recommend-article-info">
                <p class="recommend-article-title">逆水寒手游自选金打造使用推荐 全新职业PVE一图流</p>
                <p class="recommend-article-time">2024-07-18 08:30:00</p>
            </div>
        </li>
        <li>
            <img class="recommend-article-pic" src="https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/202a32a24b344e68917cd44222662fd0_mergeImage.png" alt="文章图片" >
            <div class="recommend-article-info">
                <p class="recommend-article-title">穿越火线卖号去哪里 穿越火线靠谱的平台是哪个</p>
                <p class="recommend-article-time">2024-07-18 08:30:00</p>
            </div>
        </li>
        <li>
            <img class="recommend-article-pic" src="https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/56181b5384c64c57bfdd5ec531f16470_mergeImage.png" alt="文章图片" >
            <div class="recommend-article-info">
                <p class="recommend-article-title">逆水寒手游自选金打造使用推荐 全新职业PVE一图流</p>
                <p class="recommend-article-time">2024-07-18 08:30:00</p>
            </div>
        </li>
        <li>
            <img class="recommend-article-pic" src="https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/934d3a5a6c5c4ec59933bd6dac74f8f9_mergeImage.png" alt="文章图片" >
            <div class="recommend-article-info">
                <p class="recommend-article-title">穿越火线哪里买号安全 穿越火线账号交易平台来了</p>
                <p class="recommend-article-time">2024-07-18 08:30:00</p>
            </div>
        </li>
    </ul>
  </div>
</template>

<script>

export default {
    // name:'RecommendArticleList'
}
</script>

<style lang="less" scoped>
.recommend-list-content{
    width:320px;
    padding: 0 14px;
    .article-main-title{
        margin-top: 12px;
        height: 20px;
        font-family: MicrosoftYaHei, MicrosoftYaHei;
        font-weight: bold;
        font-size: 15px;
        color: #222222;
        line-height: 20px;
        text-align: left;
        font-style: normal;
    }
    ul{
        width:292px;
        list-style-type: none;
        li{
            margin-top: 30px;
            list-style: none;
            display: flex;
            cursor: default;
            .recommend-article-pic{
                width: 100px;
                height: 56px;
                background: #DEDEDE;
                border-radius: 4px;
            }
            .recommend-article-info{
                margin-left: 10px;
                width:182px;
                display: flex;
                flex-direction: column;
                .recommend-article-title{
                    height: 34px;
                    font-family: MicrosoftYaHei;
                    font-size: 13px;
                    color: #131313;
                    line-height: 17px;
                    text-align: justify;
                    font-style: normal;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    line-clamp: 2;
                    -webkit-line-clamp:2;
                    word-break: break-all;
                }
                .recommend-article-time{
                    margin-top: 6px;
                    height: 16px;
                    font-family: MicrosoftYaHei;
                    font-size: 12px;
                    color: #999999;
                    line-height: 16px;
                    text-align: left;
                    font-style: normal;
                }
            }
            &:first-child{
                margin-top: 20px;
            }
            &:last-child{
                margin-bottom: 20px;
            }
            &:hover .recommend-article-title{
                color: #F02233;
            }
        }
    }
}
</style>